// Form.jsx
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "./ui/Button";
import Input from "./ui/Input";
import TextArea from "./ui/TextArea";
import Select from "./ui/Select";
import ilIlceData from "../assets/data/il-ilce.json";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Form = () => {
  const [ilceler, setIlceler] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (shouldScroll && formRef.current) {
      const yOffset = -100;
      const y =
        formRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const initialValues = {
    ad: "",
    soyad: "",
    cepTelefonu: "05",
    il: "",
    ilce: "",
    dogumTarihi: "",
    epostaAdresi: "",
    meslek: "",
    egitimDurumu: "",
    mezunOlunanSonOkul: "",
    alanBolum: "",
    instagram: "",
    twitter: "",
    referans: "",
    hakkinda: "",
    kvkkAccepted: false,
    teskilatCalisma: "",
  };

  const validationSchema = Yup.object({
    ad: Yup.string()
      .required("Ad gereklidir")
      .min(2, "Ad en az 2 karakter olmalıdır")
      .max(50, "Ad en fazla 50 karakter olmalıdır"),
    soyad: Yup.string()
      .required("Soyad gereklidir")
      .min(2, "Soyad en az 2 karakter olmalıdır")
      .max(50, "Soyad en fazla 50 karakter olmalıdır"),
    cepTelefonu: Yup.string()
      .matches(/^05\d{9}$/, "Telefon numarası 11 haneli olmalıdır.")
      .required("Cep Telefonu gereklidir"),
    il: Yup.string().required("İl gereklidir"),
    ilce: Yup.string().required("İlçe gereklidir"),
    dogumTarihi: Yup.date()
      .max(new Date(), "Doğum Tarihi gelecekte olamaz")
      .required("Doğum Tarihi gereklidir"),
    epostaAdresi: Yup.string()
      .email("Geçerli bir e-posta adresi giriniz")
      .required("E-posta Adresi gereklidir"),
    meslek: Yup.string()
      .required("Meslek gereklidir")
      .min(2, "Meslek en az 2 karakter olmalıdır")
      .max(50, "Meslek en fazla 50 karakter olmalıdır"),
    egitimDurumu: Yup.string().required("Eğitim Durumu seçiniz"),
    mezunOlunanSonOkul: Yup.string()
      .required("Mezun Olunan Son Okul gereklidir")
      .min(2, "Okul adı en az 2 karakter olmalıdır")
      .max(50, "Okul adı en fazla 50 karakter olmalıdır"),
    alanBolum: Yup.string()
      .required("Alan/Bölüm gereklidir")
      .min(2, "Alan/Bölüm en az 2 karakter olmalıdır")
      .max(50, "Alan/Bölüm en fazla 50 karakter olmalıdır"),
    instagram: Yup.string().max(
      75,
      "Instagram kullanıcı adı en fazla 75 karakter olmalıdır"
    ),
    twitter: Yup.string().max(
      75,
      "Twitter kullanıcı adı en fazla 75 karakter olmalıdır"
    ),
    hakkinda: Yup.string().max(
      500,
      "Hakkında alanı en fazla 500 karakter olmalıdır"
    ),
    kvkkAccepted: Yup.boolean()
      .oneOf([true], "KVKK metnini kabul etmelisiniz")
      .required("KVKK metnini kabul etmelisiniz"),
    teskilatCalisma: Yup.string()
      .oneOf(
        ["evet", "hayir"],
        "Lütfen teşkilatlarda görev alma durumunuzu seçiniz."
      )
      .required("Lütfen teşkilatlarda görev alma durumunuzu seçiniz."),
  });

  const onSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    try {
      if (values.hakkinda && values.hakkinda.trim() === "") {
        values.hakkinda = null;
      }

      if (values.referans && values.referans.trim() === "") {
        values.referans = null;
      }

      const response = await axios.post(
        "https://request-form-backend.onrender.com/api/forms",
        // "http://localhost:8080/api/forms", // For local development
        values
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text: "Form başarıyla gönderildi!",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#3b82f6",
        });
        resetForm();
        setIlceler([]);
        setShouldScroll(true);
      }
    } catch (error) {
      console.error("Form gönderilirken hata oluştu:", error);
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: "Form gönderilirken bir hata oluştu. Lütfen tekrar deneyin.",
        confirmButtonText: "Tamam",
        confirmButtonColor: "#3b82f6",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ errors, touched, values, setFieldValue, isValid }) => (
        <FormikForm
          ref={formRef}
          className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg"
          aria-labelledby="form-heading"
        >
          <h2 id="form-heading" className="text-2xl font-bold mb-6 text-center">
            Talep Formu
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Ad */}
            <Field name="ad">
              {({ field }) => (
                <Input
                  label="Ad"
                  name="ad"
                  {...field}
                  required
                  error={touched.ad && errors.ad ? errors.ad : null}
                />
              )}
            </Field>

            {/* Soyad */}
            <Field name="soyad">
              {({ field }) => (
                <Input
                  label="Soyad"
                  name="soyad"
                  {...field}
                  required
                  error={touched.soyad && errors.soyad ? errors.soyad : null}
                />
              )}
            </Field>

            {/* Cep Telefonu Field */}
            <Field name="cepTelefonu">
              {({ field }) => (
                <Input
                  label="Cep Telefonu"
                  name="cepTelefonu"
                  type="tel"
                  pattern="05\d{9}"
                  placeholder="05xxxxxxxxx"
                  value={field.value}
                  required
                  error={
                    touched.cepTelefonu && errors.cepTelefonu
                      ? errors.cepTelefonu
                      : null
                  }
                  onChange={(e) => {
                    let inputValue = e.target.value.replace(/\D/g, "");

                    if (!inputValue.startsWith("05")) {
                      inputValue = "05";
                    }

                    if (inputValue.length > 11) {
                      inputValue = inputValue.slice(0, 11);
                    }

                    setFieldValue("cepTelefonu", inputValue);
                  }}
                />
              )}
            </Field>

            {/* İl */}
            <Field name="il">
              {({ field }) => (
                <Select
                  label="İl"
                  name="il"
                  {...field}
                  required
                  error={touched.il && errors.il ? errors.il : null}
                  options={ilIlceData.data.map((il) => ({
                    value: il.il_adi,
                    label: capitalizeFirstLetter(il.il_adi),
                  }))}
                  onChange={(e) => {
                    const selectedIl = e.target.value;
                    setFieldValue("il", selectedIl);
                    setFieldValue("ilce", "");
                    const selectedIlData = ilIlceData.data.find(
                      (il) => il.il_adi === selectedIl
                    );
                    setIlceler(selectedIlData ? selectedIlData.ilceler : []);
                  }}
                />
              )}
            </Field>

            {/* İlçe */}
            <Field name="ilce">
              {({ field }) => (
                <Select
                  label="İlçe"
                  name="ilce"
                  {...field}
                  required
                  error={touched.ilce && errors.ilce ? errors.ilce : null}
                  options={ilceler.map((ilce) => ({
                    value: ilce.ilce_adi,
                    label: capitalizeFirstLetter(ilce.ilce_adi),
                  }))}
                  disabled={!values.il}
                />
              )}
            </Field>

            {/* Doğum Tarihi */}
            <Field name="dogumTarihi">
              {({ field }) => (
                <Input
                  label="Doğum Tarihi"
                  name="dogumTarihi"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  {...field}
                  required
                  error={
                    touched.dogumTarihi && errors.dogumTarihi
                      ? errors.dogumTarihi
                      : null
                  }
                />
              )}
            </Field>

            {/* E-posta Adresi */}
            <Field name="epostaAdresi">
              {({ field }) => (
                <Input
                  label="E-posta Adresi"
                  name="epostaAdresi"
                  type="email"
                  {...field}
                  required
                  error={
                    touched.epostaAdresi && errors.epostaAdresi
                      ? errors.epostaAdresi
                      : null
                  }
                />
              )}
            </Field>

            {/* Teşkilatlarda Görev Almak İstiyor Musunuz? */}
            <Field name="teskilatCalisma">
              {({ field }) => (
                <Select
                  label="Teşkilatlarda Görev Almak İstiyor Musunuz?"
                  name="teskilatCalisma"
                  {...field}
                  required
                  error={
                    touched.teskilatCalisma && errors.teskilatCalisma
                      ? errors.teskilatCalisma
                      : null
                  }
                  options={[
                    { value: "evet", label: "Evet, istiyorum" },
                    { value: "hayir", label: "Hayır, istemiyorum" },
                  ]}
                />
              )}
            </Field>

            {/* Meslek */}
            <Field name="meslek">
              {({ field }) => (
                <Input
                  label="Meslek"
                  name="meslek"
                  {...field}
                  required
                  error={touched.meslek && errors.meslek ? errors.meslek : null}
                />
              )}
            </Field>

            {/* Eğitim Durumu */}
            <Field name="egitimDurumu">
              {({ field }) => (
                <Select
                  label="Eğitim Durumu"
                  name="egitimDurumu"
                  {...field}
                  required
                  error={
                    touched.egitimDurumu && errors.egitimDurumu
                      ? errors.egitimDurumu
                      : null
                  }
                  options={[
                    { value: "ilkokul", label: "İlkokul" },
                    { value: "ortaokul", label: "Ortaokul" },
                    { value: "lise", label: "Lise" },
                    { value: "universite", label: "Üniversite" },
                    { value: "yukseklisans", label: "Yüksek Lisans" },
                    { value: "doktora", label: "Doktora" },
                  ]}
                />
              )}
            </Field>

            {/* Mezun Olunan Son Okul */}
            <Field name="mezunOlunanSonOkul">
              {({ field }) => (
                <Input
                  label="Mezun Olunan Son Okul"
                  name="mezunOlunanSonOkul"
                  {...field}
                  required
                  error={
                    touched.mezunOlunanSonOkul && errors.mezunOlunanSonOkul
                      ? errors.mezunOlunanSonOkul
                      : null
                  }
                />
              )}
            </Field>

            {/* Alan/Bölüm */}
            <Field name="alanBolum">
              {({ field }) => (
                <Input
                  label="Alan/Bölüm"
                  name="alanBolum"
                  {...field}
                  required
                  error={
                    touched.alanBolum && errors.alanBolum
                      ? errors.alanBolum
                      : null
                  }
                />
              )}
            </Field>

            {/* Instagram */}
            <Field name="instagram">
              {({ field }) => (
                <Input
                  label="Instagram Kullanıcı Adı"
                  name="instagram"
                  {...field}
                  required={false}
                  error={
                    touched.instagram && errors.instagram
                      ? errors.instagram
                      : null
                  }
                />
              )}
            </Field>

            {/* Twitter */}
            <Field name="twitter">
              {({ field }) => (
                <Input
                  label="Twitter Kullanıcı Adı"
                  name="twitter"
                  {...field}
                  required={false}
                  error={
                    touched.twitter && errors.twitter ? errors.twitter : null
                  }
                />
              )}
            </Field>

            {/* Referans (yeni eklenen alan) */}
            <Field name="referans">
              {({ field }) => (
                <Input
                  label="Referans"
                  name="referans"
                  {...field}
                  required={false}
                  error={
                    touched.referans && errors.referans ? errors.referans : null
                  }
                />
              )}
            </Field>
          </div>

          {/* Hakkında (Zorunlu değil) */}
          <Field name="hakkinda">
            {({ field }) => (
              <TextArea
                label="Hakkında"
                name="hakkinda"
                {...field}
                required={false}
                className="mt-4"
                rows={4}
                maxLength={500}
                error={
                  touched.hakkinda && errors.hakkinda ? errors.hakkinda : null
                }
              />
            )}
          </Field>

          {/* KVKK Onayı */}
          <div className="mt-4">
            <label className="flex items-center text-sm text-gray-600">
              <Field name="kvkkAccepted">
                {({ field }) => (
                  <input
                    type="checkbox"
                    {...field}
                    checked={field.value}
                    aria-required="true"
                  />
                )}
              </Field>
              <span className="ml-2">
                Kişisel verilerin işlenmesiyle ilgili{" "}
                <a
                  href="/kvkk.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  aydınlatma metni
                </a>{" "}
                okuyup anladığımı beyan ve kabul ederim.*
              </span>
            </label>
            {errors.kvkkAccepted && touched.kvkkAccepted && (
              <div className="text-red-500 text-sm" role="alert">
                {errors.kvkkAccepted}
              </div>
            )}
          </div>

          <div className="mt-6 text-center">
            <Button
              type="submit"
              className="mx-auto"
              style={{ width: "200px" }}
              disabled={isSubmitting || !isValid}
              aria-disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? "Gönderiliyor..." : "Formu Gönder"}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
