import React, { useEffect, useRef } from "react";

const TwitterTimeline = () => {
  const timelineRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";

    const currentRef = timelineRef.current;

    if (currentRef) {
      currentRef.appendChild(script);
    }

    return () => {
      if (currentRef) {
        currentRef.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="container mx-auto my-4 p-4 bg-white rounded-lg shadow-md max-w-lg">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full max-w-[400px]" ref={timelineRef}>
          <a
            className="twitter-timeline"
            data-lang="tr"
            data-width="400"
            data-height="600"
            href="https://twitter.com/yavuzagiraliog?ref_src=twsrc%5Etfw"
            rel="nofollow noreferrer"
            aria-label="Yavuz Ağıralioğlu'nun Twitter Gönderileri"
          ></a>
        </div>
      </div>
    </div>
  );
};

export default TwitterTimeline;
