import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageSection from "../components/ImageSection";
import TextSection from "../components/TextSection";
import Form from "../components/Form";
import Slider from "../components/Slider";
import { FaXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";
import TwitterTimeline from "../components/TwitterTimeline";

const HomePage = () => {
  const sliderItems = [
    {
      image: "/images/haber61.png",
      title:
        "Yavuz Ağıralioğlu, Anadolu Yakası Muhtarlar buluşmasında muhtarlara seslendi.",
      link: "https://www.dha.com.tr/politika/yavuz-agiralioglu-yeni-parti-kurmak-icin-calismalarimiz-suruyor-2483397",
      alt: "Yavuz Ağıralioğlu muhtarlar toplantısında konuşurken",
      description:
        "Yavuz Ağıralioğlu, Anadolu Yakası muhtarlarıyla bir araya gelerek yeni parti çalışmaları hakkında bilgi verdi.",
    },
    {
      image: "/images/haber3.jpeg",
      title: "Yavuz Ağıralioğlu'nun A planı 'Liyakatli Şöhretsizler'",
      link: "https://haberglobal.com.tr/amp/gundem/yavuz-agiralioglunun-a-plani-liyakatli-sohretsizler-375353",
      alt: "Yavuz Ağıralioğlu'nun 'Liyakatli Şöhretsizler' planını açıklarken",
      description:
        "Yavuz Ağıralioğlu, yeni parti vizyonunda 'Liyakatli Şöhretsizler' konseptini ön plana çıkarıyor.",
    },
    {
      image: "/images/haber4.jpeg",
      title:
        "Yavuz Ağıralioğlu: Yeni parti kurmak için çalışmalarımız sürüyor.",
      link: "https://m.haber7.com/amphtml/siyaset/haber/3437581-yavuz-agiralioglu-partisinin-tabelasini-asacagi-tarihi-acikladi",
      alt: "Yavuz Ağıralioğlu yeni parti çalışmalarını anlatırken",
      description:
        "Yavuz Ağıralioğlu, yeni parti kuruluş çalışmalarının devam ettiğini ve yakında sonuçlanacağını açıkladı.",
    },
    {
      image: "/images/haber5.jpeg",
      title: "Yavuz Ağıralioğlu'nun söylemleri eyleme dönüşüyor.",
      link: "https://www.yerelgazete.com.tr/yavuz-agiralioglunun-soylemleri-eyleme-donusuyor/",
      alt: "Yavuz Ağıralioğlu'nun siyasi eylemlerini gerçekleştirirken",
      description:
        "Yavuz Ağıralioğlu'nun politik söylemleri somut eylemlere dönüşüyor, yeni parti hazırlıkları hız kazanıyor.",
    },
    {
      image: "/images/haber6.jpeg",
      title: "Yavuz Ağıralioğlu partisini kuruyor.",
      link: "https://www.cumhuriyet.com.tr/siyaset/yavuz-agiralioglu-partisini-kuruyor-2209220",
      alt: "Yavuz Ağıralioğlu yeni parti kuruluş çalışmalarını yürütürken",
      description:
        "Yavuz Ağıralioğlu, yeni partisinin kuruluş çalışmalarını tamamlamak üzere olduğunu duyurdu.",
    },
    {
      image: "/images/haber7.jpeg",
      title: "Yavuz Ağıralioğlu kimdir?",
      link: "https://www.ntv.com.tr/galeri/turkiye/yavuz-agiralioglu-kimdir-kac-yasinda-ve-nereli-yavuz-agiralioglunun-biyografisi,gvYsnwFVCkms1FZ5KkcQHg/sagCYHNU7EmRwh4P3v-UXQ",
      alt: "Yavuz Ağıralioğlu'nun portre fotoğrafı",
      description:
        "Yavuz Ağıralioğlu'nun hayatı, siyasi kariyeri ve yeni parti vizyonu hakkında detaylı bilgi.",
    },
    {
      image: "/images/haber8.jpeg",
      title:
        "Ayhan Erel kurulacak yeni parti çalışmaları ile ilgili bilgi verdi.",
      link: "https://t24.com.tr/video/yavuz-agiralioglu-nun-yeni-parti-calismalari-kapsaminda-aksaray-da-istisare-toplantisi-yapildi,61272",
      alt: "Ayhan Erel yeni parti çalışmaları hakkında açıklama yaparken",
      description:
        "Yavuz Ağıralioğlu'nun yeni parti çalışmaları kapsamında Aksaray'da düzenlenen istişare toplantısı hakkında bilgiler.",
    },
  ];

  const reorderedSliderItems = [
    sliderItems[2],
    sliderItems[1],
    sliderItems[6],
    sliderItems[4],
    sliderItems[3],
    sliderItems[5],
    sliderItems[0],
  ];

  const [isTimelineVisible, setIsTimelineVisible] = useState(true);
  const [isTimelineLoaded, setIsTimelineLoaded] = useState(false);
  const timelineRef = useRef(null);

  const toggleTimeline = useCallback(() => {
    setIsTimelineVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    const currentRef = timelineRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isTimelineLoaded) {
          setIsTimelineLoaded(true);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isTimelineLoaded]);

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Borcumuz Türkiye'ye",
    url: "https://borcumuzturkiyeye.com",
    logo: "https://borcumuzturkiyeye.com/logo.png",
    sameAs: [
      "https://yavuzagiralioglu.com",
      "https://twitter.com/yavuzagiraliog",
      "https://www.facebook.com/yavuzagiraliogluresmisayfasi",
      "https://www.instagram.com/yavuzagiralioglu/",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      email: "bilgi@borcumuzturkiyeye.com",
      contactType: "customer service",
      areaServed: "TR",
      availableLanguage: ["Turkish"],
    },
    founder: {
      "@type": "Person",
      name: "Yavuz Ağıralioğlu",
      jobTitle: "27. Dönem Milletvekili",
      url: "https://yavuzagiralioglu.com",
    },
    description:
      "Yavuz Ağıralioğlu önderliğinde Türkiye için yeni bir vizyon. Milletimizin beklentilerine cevap veren, liyakate dayalı siyasi hareket.",
    slogan: "Borcumuz Türkiye'ye!",
  };

  return (
    <>
      <Helmet>
        <title>
          Yavuz Ağıralioğlu | Türkiye İçin Yeni Bir Vizyon - Borcumuz Türkiye'ye
        </title>
        <meta
          name="description"
          content="Yavuz Ağıralioğlu önderliğinde Türkiye için yeni bir vizyon. Milletimizin beklentilerine cevap veren, liyakate dayalı siyasi hareket. Borcumuz Türkiye'ye!"
        />
        <meta
          name="keywords"
          content="Yavuz Ağıralioğlu, Borcumuz Türkiye'ye, yeni siyasi hareket, Türkiye, liyakat, şöhretsizler, muhalefet, siyaset, demokrasi, adalet, kalkınma, milli irade, Türkiye yeni parti, Anadolu yakası muhtarlar, Aksaray istişare toplantısı, siyasi eylem, siyasi liderlik"
        />
        <link rel="canonical" href="https://borcumuzturkiyeye.com" />
        <link rel="alternate" href="https://yavuzagiralioglu.com" />
        <meta
          property="og:title"
          content="Yavuz Ağıralioğlu | Borcumuz Türkiye'ye"
        />
        <meta
          property="og:description"
          content="Yavuz Ağıralioğlu önderliğinde Türkiye için yeni bir vizyon. Milletimizin beklentilerine cevap veren, liyakate dayalı siyasi hareket."
        />
        <meta
          property="og:image"
          content="https://borcumuzturkiyeye.com/images/agiralioglu1.jpeg"
        />
        <meta property="og:url" content="https://borcumuzturkiyeye.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Borcumuz Türkiye'ye" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@yavuzagiraliog" />
        <meta name="twitter:creator" content="@yavuzagiraliog" />
        <meta
          name="twitter:title"
          content="Yavuz Ağıralioğlu | Borcumuz Türkiye'ye"
        />
        <meta
          name="twitter:description"
          content="Türkiye için yeni bir vizyon. Milletimizin beklentilerine cevap veren, liyakate dayalı siyasi hareket."
        />
        <meta
          name="twitter:image"
          content="https://borcumuzturkiyeye.com/images/banner.jpeg"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Yavuz Ağıralioğlu" />
        <link
          rel="alternate"
          hreflang="tr"
          href="https://borcumuzturkiyeye.com"
        />
        <link rel="icon" href="/logo.png" type="image/png" sizes="32x32" />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <div className="flex flex-col min-h-screen">
        <ImageSection altText="Yavuz Ağıralioğlu ve Borcumuz Türkiye'ye vizyonu" />
        <Header />
        <main className="flex-grow">
          <h1 className="sr-only">
            Yavuz Ağıralioğlu: Türkiye İçin Yeni Bir Vizyon
          </h1>
          <TextSection />
          <section aria-label="Katılım Formu">
            <h2 className="sr-only">Borcumuz Türkiye'ye Hareketine Katılın</h2>
            <Form />
          </section>
          <section aria-label="Haberler">
            <Slider items={reorderedSliderItems} />
          </section>
          <section aria-label="Sosyal Medya" ref={timelineRef}>
            <div className="flex justify-center my-6">
              <button
                className="flex items-center justify-center px-4 py-2 bg-black text-white rounded-full shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
                onClick={toggleTimeline}
                aria-expanded={isTimelineVisible}
              >
                <FaXTwitter className="mr-2" aria-hidden="true" />
                {isTimelineVisible
                  ? "Gönderilerini Gizle"
                  : "Gönderilerini Göster"}
              </button>
            </div>
            {(isTimelineLoaded || isTimelineVisible) && (
              <div style={{ display: isTimelineVisible ? "block" : "none" }}>
                <TwitterTimeline />
              </div>
            )}
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
