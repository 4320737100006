// TextSection.jsx
import React from 'react';

const TextSection = () => {
  return (
    <section className="py-4 relative text-black" aria-labelledby="text-section-heading">
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-2xl mx-auto text-center">
          <h2 id="text-section-heading" className="text-base md:text-lg font-normal mb-2">
            <strong>TÜRKİYE İÇİN</strong>
          </h2>
          <p className="text-[12px] md:text-[20px] leading-snug">
            Milletimizin beklentilerine, itirazlarına ve ümitlerine ortak olarak yürüdüğümüz uzun yolculuğumuz bir siyasi partinin kuruluşunu zorunlu kıldı. Akıl, ahlak ve adalet ile yola çıkacağımız, milletimizin tümünün “benim” diyeceği, “benimseyeceği” bir siyasi partinin kuruluş hazırlıklarında son aşamaya geldik. Kuracak olduğumuz partinin asıl sahibi “milletimizdir.” Bütün mücadelemiz ve gayretimiz, çocuklarımıza güzel bir memleket bırakabilmek için olacaktır. Milletimizin ufkuna doğmak için, Türkiye için hizalanıyoruz.
          </p>
          <p className="text-[12px] md:text-[20px] leading-snug mt-1">
            Başlatmış olduğumuz bu değerli yürüyüşün bir parçası olmak istiyorsanız formu eksiksiz bir şekilde doldurun. En kısa zamanda sizinle iletişime geçeceğiz.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TextSection;
