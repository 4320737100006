// TextArea.jsx
import React, { useState } from "react";

const TextArea = ({ label, name, placeholder, value, onChange, onBlur, required = false, rows = 4, className = "", error }) => {
  const [charCount, setCharCount] = useState(value?.length || 0);
  const maxLength = 500;

  const handleInputChange = (e) => {
    onChange(e);
    setCharCount(e.target.value.length);
  };

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <textarea
        id={name}
        name={name}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onBlur={onBlur}
        maxLength={maxLength}
        className={`w-full px-3 py-2 placeholder-gray-400 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md focus:outline-none focus:ring focus:ring-blue-100 focus:border-blue-300 ${className}`}
        style={{ resize: "none" }}
        aria-invalid={error ? "true" : "false"}
        aria-describedby={`${name}-charcount ${error ? `${name}-error` : ''}`}
      />
      <p id={`${name}-charcount`} className="mt-1 text-xs text-gray-500">
        {charCount}/{maxLength} karakter
      </p>
      {error && <p id={`${name}-error`} className="mt-1 text-xs text-red-500">{error}</p>}
    </div>
  );
};

export default TextArea;
