// Button.jsx
import React from 'react';

const Button = ({
  children,
  onClick,
  type = 'button',
  className = '',
  disabled = false,
  ariaLabel = '',
}) => {
  return (
    <button
      type={type}
      className={`px-4 py-2 font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-75 ${
        disabled
          ? 'bg-blue-400 text-white cursor-not-allowed opacity-50'
          : 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-400'
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel || children}
    >
      {children}
    </button>
  );
};

export default Button;
