// Slider.jsx
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Card from './ui/Card';

const Slider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(getCardsToShow());

  function getCardsToShow() {
    if (window.innerWidth >= 1024) {
      return 4;
    } else if (window.innerWidth >= 768) {
      return 2;
    } else {
      return 2; // Show 1 item on mobile for better visibility
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setCardsToShow(getCardsToShow());
    };

    window.addEventListener('resize', handleResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + cardsToShow < items.length ? prevIndex + 1 : 0
      );
    }, 5000);

    return () => clearInterval(autoSlide);
  }, [cardsToShow, items.length]);

  const nextSlide = () => {
    if (currentIndex + cardsToShow < items.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(items.length - cardsToShow);
    }
  };

  return (
    <div className="relative w-full max-w-6xl mx-auto my-12" aria-label="Haberler Slider">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${(currentIndex * 100) / cardsToShow}%)` }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0"
              style={{ width: `${100 / cardsToShow}%`, padding: '0 8px' }}
            >
              <Card
                image={item.image}
                title={item.title}
                description={item.description}
                link={item.link}
                alt={item.alt}
              />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        disabled={items.length <= cardsToShow}
        aria-label="Önceki Haber"
      >
        <ChevronLeft className="w-6 h-6 text-gray-800" />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        disabled={items.length <= cardsToShow}
        aria-label="Sonraki Haber"
      >
        <ChevronRight className="w-6 h-6 text-gray-800" />
      </button>
    </div>
  );
};

export default Slider;
