// App.jsx
import React from 'react';
import HomePage from './pages/HomePage';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <HomePage />
      </div>
    </HelmetProvider>
  );
}

export default App;
