import React from 'react';

const ImageSection = () => {
  return (
    <div
      className="relative w-full overflow-hidden"
      role="img"
      aria-label="Memleket için çalışıyoruz"
    >
      <img
        src="/images/banner.jpeg"
        alt="Memleket için çalışıyoruz"
        className="w-full h-auto max-h-[400px] sm:max-h-[500px] md:max-h-[600px] lg:max-h-[700px] xl:max-h-[800px] object-cover object-top"
        loading="lazy"
      />
    </div>
  );
};

export default ImageSection;
