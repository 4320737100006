// Card.jsx
import React from 'react';

const Card = ({ image, title, description, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block"
      aria-label={`Read more about ${title}`}
    >
      <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden shadow-lg">
        <img className="w-full h-full object-cover" src={image} alt={title} />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
        <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
          <h3 className="font-bold text-base mb-1">{title}</h3>
          {/* Uncomment the description if needed */}
          {/* <p className="text-sm">{description}</p> */}
        </div>
      </div>
    </a>
  );
};

export default Card;
