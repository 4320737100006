// Select.jsx
import React from "react";

const Select = ({ 
  label, 
  name, 
  options, 
  value, 
  onChange, 
  onBlur, 
  required = false, 
  className = "", 
  error, 
  disabled = false,
  ariaLabel = '',
}) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        className={`w-full px-3 py-2 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md focus:outline-none focus:ring focus:ring-blue-100 focus:border-blue-300 ${
          disabled ? "bg-gray-100 cursor-not-allowed" : ""
        } ${className}`}
        aria-label={ariaLabel || label}
        aria-invalid={error ? "true" : "false"}
        aria-describedby={error ? `${name}-error` : undefined}
      >
        <option value="">Seçiniz</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}  
          </option>
        ))}
      </select>
      {error && <p id={`${name}-error`} className="mt-1 text-xs text-red-500">{error}</p>}
    </div>
  );
};

export default Select;
